import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/kontext.svg';
import bg from '../../images/main_bg.jpeg';
import Footer from '../Footer';
import './index.scss';

const MainPage = () => {
    return (
        <main className='homepage'>
            <div className='main-bg'>
                <img alt='kontext-background' src={bg} />
            </div>
            <div className='container'>
                <div className='homepage__wrapper'>
                    <Link to='/'>
                        <img alt='logo-kontext' src={logo} />
                    </Link>
                    <div className='homepage__wrapper__content'>
                        <p>Consented, shopping-focused consumer insights</p>
                        <a href='mailto:info@kontextdata.com'>Contact us</a>
                    </div>
                </div>
                <Footer />
            </div>
        </main>
    );
};

export default MainPage;
