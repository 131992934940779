import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/kontext.svg';
import bg from '../../images/main_bg.jpeg';
import Footer from '../Footer';
import './index.scss';

const OptOut = () => {
    return (
        <main className='opt-out'>
            <div className='main-bg'>
                <img alt='kontext-background' src={bg} />
            </div>
            <div className='container'>
                <Link className='app-logo' to='/'>
                    <img alt='logo-kontext' src={logo} />
                </Link>
                <div className='opt-out-mid-area'>
                    <div className='opt-out-list-head'>
                        <div className='opt-out-list-head-left'>
                            <h1 className='opt-out-list-head-left__title'>Right to Opt Out of Sale of Information</h1>
                        </div>
                        <div className='opt-out-list-head-right'>
                            <p>Last Updated August 10, 2023</p>
                        </div>
                    </div>
                    <br />
                    <p>
                        To opt out of the sale of your personal information, please submit a request by emailing the
                        following info to <a href='mailto:privacy@kontextdata.com'>privacy@kontextdata.com</a>.
                    </p>
                    <ul>
                        <li>First name</li>
                        <li>Last name</li>
                        <li>Email</li>
                        <li>Street Address</li>
                        <li>City</li>
                        <li>State</li>
                        <li>Postal Code</li>
                        <li>Country</li>
                    </ul>
                    <br />
                    <p>
                        The right to opt-out is subject to certain exemptions and exceptions. For example, it does not
                        apply to personal information that we share with service providers that perform certain business
                        functions for us pursuant to a written contract. Additionally, we may be unable to fulfill your
                        request if we believe the request is fraudulent.
                    </p>
                    <p>
                        Please visit our <Link to='/privacy'>Privacy Policy</Link> for additional information about your
                        rights.
                    </p>
                </div>
                <Footer />
            </div>
        </main>
    );
};

export default OptOut;
